/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';

import abi from 'libs/contracts/generated/infos/abis/XvsTokenOmnichain.json';
import { XvsTokenOmnichain } from 'libs/contracts/generated/infos/contractTypes';
import { getUniqueContractAddress } from 'libs/contracts/utilities/getUniqueContractAddress';
import { useChainId, useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as xvsTokenOmnichainAbi } from 'libs/contracts/generated/infos/abis/XvsTokenOmnichain';

interface GetXvsTokenOmnichainContractAddressInput {
  chainId: ChainId;
}

export const getXvsTokenOmnichainContractAddress = ({
  chainId,
}: GetXvsTokenOmnichainContractAddressInput) =>
  getUniqueContractAddress({ name: 'XvsTokenOmnichain', chainId });

export const useGetXvsTokenOmnichainContractAddress = () => {
  const { chainId } = useChainId();

  return useMemo(
    () =>
      getXvsTokenOmnichainContractAddress({
        chainId,
      }),
    [chainId],
  );
};

interface GetXvsTokenOmnichainContractInput {
  chainId: ChainId;
  signerOrProvider: Signer | Provider;
}

export const getXvsTokenOmnichainContract = ({
  chainId,
  signerOrProvider,
}: GetXvsTokenOmnichainContractInput) => {
  const address = getXvsTokenOmnichainContractAddress({ chainId });
  return address ? (new Contract(address, abi, signerOrProvider) as XvsTokenOmnichain) : undefined;
};

interface UseGetXvsTokenOmnichainContractInput {
  passSigner?: boolean;
  chainId?: ChainId;
}

export const useGetXvsTokenOmnichainContract = (input?: UseGetXvsTokenOmnichainContractInput) => {
  const { chainId: currentChainId } = useChainId();
  const chainId = input?.chainId || currentChainId;

  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = input?.passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getXvsTokenOmnichainContract({
            chainId,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, chainId],
  );
};

    