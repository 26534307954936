/* Automatically generated file, do not update manually */
    import type { Provider } from '@ethersproject/abstract-provider';
import { Contract, Signer } from 'ethers';
import { useMemo } from 'react';
import type { Address } from 'viem';

import abi from 'libs/contracts/generated/infos/abis/VBnb.json';
import { VBnb } from 'libs/contracts/generated/infos/contractTypes';
import { useProvider, useSigner } from 'libs/wallet';
import { ChainId } from 'types';

export { abi as vBnbAbi } from 'libs/contracts/generated/infos/abis/VBnb';

interface GetVBnbContractInput {
  address: Address;
  signerOrProvider: Signer | Provider;
}

export const getVBnbContract = ({ signerOrProvider, address }: GetVBnbContractInput) =>
  new Contract(address, abi, signerOrProvider) as VBnb;

interface UseGetVBnbContractInput {
  passSigner: boolean;
  address: Address;
  chainId?: ChainId;
}

export const useGetVBnbContract = ({
  passSigner = false,
  address,
  chainId,
}: UseGetVBnbContractInput) => {
  const { provider } = useProvider({ chainId });
  const { signer } = useSigner({ chainId });
  const signerOrProvider = passSigner ? signer : provider;

  return useMemo(
    () =>
      signerOrProvider
        ? getVBnbContract({
            address,
            signerOrProvider,
          })
        : undefined,
    [signerOrProvider, address],
  );
};

    